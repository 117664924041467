.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
  height: 100%;
}
.react-datepicker__input-container {
  width: inherit;
  height: inherit;
  border-bottom: 1px;
}
.react-datepicker__input-container input {
  width: inherit;
  height: 100%;
  border: 0px;
  border-bottom: 1px;
  border-style: solid;
  border-color: #bbbbbb;
  border-radius: 0rem;
  padding: 0rem;
  padding-bottom: 5px;
  padding-top: 1rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
