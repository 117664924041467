html,
body,
#root {
  height: 100%;
  /* background-color: #fbfbfb; */
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.max-height {
  height: 100%;
}

.google-login-btn {
  padding: 2px 25px 2px 25px !important;
}

.menuToRight {
  margin-left: 19rem;
}

.MuiDrawerOverride {
  display: flex;
  width: 0 !important;
}

.MuiAutocomplete-option.Mui-focused {
  background-color: #E7E7E7 !important
}

/* Hide scrollbar for Chrome, Safari and Opera */
.side-nav-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.side-nav-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-side-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-side-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.apple-auth-btn {
  width: 300px
}